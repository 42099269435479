@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
$red: #d61d09;

.btn {
  &--add {
    display: inline-block;
    color: #fff;
    background: #00629c;
    border-radius: 8px;
    font-weight: bold;
    padding: 20px 20px;
    text-decoration: none;
    transition: background-color .4s;
    text-transform: uppercase;
    font-size: 20px;
    width: 100%;
    &:hover {
      color: #fff;
      background: #008fd7;
    }
  }
}

.product {
  font-family: 'Roboto', sans-serif;
  &-price {
    color: $red;
    font-weight: 700;
    font-size: 30px;
    &-old {
      padding: 0 5px;
      color: #001a34;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $red;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
  &-row {
    display: flex;
    margin: 0 -15px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &-col {
    width: 50%;
    padding: 0 15px;
    &--advantages {
      width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  &-fast-order {
    margin-top: 15px;
    display: block;
  }
  &-info {
    border: none;
    align-items: flex-start;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 66px;
    padding: 30px;
    position: relative;
    box-shadow: 0 6px 16px rgba(0,26,52, .16), 0 0 0 rgba(0,26,52, .05);
    &-item {
      width: 100%;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      .catalog-inner-form-item {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-email-order {
      border: 2px solid #00629c;
      padding: 5px 10px;
      font-size: 16px;
      color: #383539;
    }
  }
  &-quantity {
    display: flex;
    align-items: center;
    &-control {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 30px;
      color: #00629c;
      transition: color .4s;
      &--minus {
        font-size: 40px;
      }
      &:hover {
        color: #008fd7;
      }
      &-icon {
        width: 15px;
        height: 15px;
        fill: #00629c;
        transition: fill .4s, opacity .4s;
        &:hover {
          fill: #008fd7;
        }
        &:active {
          opacity: .8;
        }
      }
    }
    &-input {
      height: 40px;
      border-color: #00629c;
      text-align: center;
      color: #00629c;
      font-size: 21px;
      padding: 5px 10px;
      max-width: 100px;
      background: #fff;
    }
  }
  &-specifications {
    &-label {
      font-size: 15px;
    }
    &-value {
      font-size: 40px;
      color: #00629c;
      line-height: 1;
      &--code {
        font-size: 24px;
      }
    }
  }
  &-advantages {
    &-item {
      display: flex;
      align-items: center;
      line-height: 1;
      margin-bottom: 8px;
    }
    &-svg {
      width: 25px;
      height: 25px;
      fill: #00629c;
    }
    &-title {
      color: $red;
      font-size: 16px;
      font-weight: bold;
      margin-left: 5px;
    }
  }
}
.catalog {
  padding-top: 50px;
  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &-col {
    width: 33.3333%;
    padding: 0 15px;
    display: flex;
    @media (max-width: 1200px) {
      width: 50%;
    }
    @media (max-width: 620px) {
      width: 100%;
    }
  }
  &-item {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: #001a34;
    justify-content: space-between;
    &-image {
      min-height: 263px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    &-price {
      margin-bottom: 20px;
      min-height: 75px;
    }
    &-title {
      font-size: 16px;
      margin-bottom: 20px;
    }
    &:hover {
      text-decoration: none;
    }
    &-add {
      &-btn {
        padding: 10px;
        max-width: 110px;
        font-size: 12px;
      }
    }
  }
}

.catalog-inner-title {
  font-size: 24px;
}

.message {
  &--danger {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 5px 10px;
    border: 1px solid;
  }
  &--red {
    color: #e74c3c;
    border-color: #e74c3c;
  }
}

.v-form {
  &-submit {
    &::focus {
      outline: none;
    }
  }
}